import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import colors from 'configs/colors';
import AppBtn from 'components/reusableComponents/AppBtn';
import AppModal from 'components/reusableComponents/AppModal';
import AppText from 'components/reusableComponents/AppText';
import LogoutCountDown from 'components/shared/LogoutCountDown';
import { signOut, signIn } from 'store/actions/auth';
import useCountdown from 'customhooks/useCountDown';
import { refreshToken } from 'apis/mutations/authentication';

const SessionTimerPrompt = () => {
  const [loading, setLoading] = useState();
  const [displayTimer, setDisplayTimer] = useState(false);
  const timerRef = useRef();
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const [mutate] = useMutation(refreshToken, {
    onMutate: () => setLoading(true),
    onSuccess: (data) => {
      dispatch(signIn(data));
      setDisplayTimer(false);
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  const handleLogOut = () => {
    dispatch(signOut(''));
    setDisplayTimer(false);
    history.push(auth);
  };

  const handleMoreTime = () => {
    mutate();
  };

  const seconds_remaining = auth.expires_in;
  const minuites_remaining = seconds_remaining / 60;

  const NOW_IN_MS = new Date().getTime();
  const targetDate = minuites_remaining * 60 * 1000 + NOW_IN_MS;
  const { minutes, seconds } = useCountdown(targetDate);
  const showTimer = seconds_remaining <= 300 && seconds_remaining > 0;

  useEffect(() => {
    if (showTimer) {
      if (timerRef.current) return null;
      timerRef.current = true;
      setDisplayTimer(true);
    }
  }, [showTimer]);

  useEffect(() => {
    if (seconds_remaining <= 0) setDisplayTimer(false);
  }, [seconds_remaining]);

  const content = (
    <Container>
      <LogoutCountDown minutes={minutes} seconds={seconds} />
      <AppText
        value={
          <>
            <div>
              Your session would expire soon, would you like to Logout or you
              need more time?
            </div>
          </>
        }
        weight='500'
        color='#484848'
        size={'16px'}
      />

      <BtnDiv>
        <AppBtn
          text={'More time'}
          radius='6px'
          width='228px'
          height='60px'
          border={`1px solid ${colors.primary}`}
          background={colors.white}
          disabled={loading}
          loading={loading}
          onClick={handleMoreTime}
          shadow={false}
          color={colors.primary}
        />

        <AppBtn
          text={'Log out'}
          radius='6px'
          width='228px'
          height='60px'
          background={colors.primary}
          onClick={handleLogOut}
          shadow={false}
          color={colors.white}
        />
      </BtnDiv>
    </Container>
  );
  return (
    <AppModal
      isModalVisible={displayTimer}
      content={content}
      closable={false}
      zIndex={3000}
    />
  );
};

export default SessionTimerPrompt;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
`;

const BtnDiv = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;
