import React from 'react';
import { Modal } from 'antd';
import ModalContent from './ModalContent';

const AppModal = ({
  isModalVisible,
  handleCancel,
  width,
  bodyStyle,
  zIndex,
  content,
  maskClosable,
  closable = true,
  ...props
}) => {
  return (
    <Modal
      {...props}
      footer=''
      title=''
      zIndex={zIndex}
      bodyStyle={bodyStyle}
      visible={isModalVisible}
      onCancel={handleCancel}
      width={width}
      maskClosable={maskClosable}
      closable={closable}
    >
      {content}
    </Modal>
  );
};

export default AppModal;
