export const ADD_BID = "ADD_BID";
export const ADD_BID_ID = "ADD_BID_ID";

export const addBid = (data) => ({
  type: ADD_BID,
  data,
});

export const addBidId = (data) => ({
  type: ADD_BID_ID,
  data,
});
