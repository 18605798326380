import * as walletTypes from 'store/actions/wallet';

const initialState = {
  bank_account: '',
  created_at: '',
  id: null,
  routing_number: '',
  stripe_account: '',
  updated_at: '',
  balance: '',
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case walletTypes.ADD_WALLET:
      return {
        bank_account: action?.data?.bank_account,
        created_at: action?.data?.created_at,
        id: action?.data?.id,
        routing_number: action?.data?.routing_number,
        stripe_account: action?.data?.stripe_account,
        updated_at: action?.data?.updated_at,
        balance: action?.data?.balance,
      };

    default:
      return state;
  }
};

export default walletReducer;
