import * as messageTypes from 'store/actions/message';

const initialState = {
    messageId : null,
}

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        // case messageTypes.ADD_MESSAGE:
        //   return {
            
        //   };
        case messageTypes.ADD_MESSAGE_ID:
            return{
                ...state,
                messageId: action.data
            }
        default:
         return state;
    }
}

export default messageReducer;