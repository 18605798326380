import * as orderTypes from 'store/actions/order';

const initialState = {
    seller: {},
    id : null,
    sellingPrice: '',
    orderedItem : {},
    orderId: null,
    transportMethod: null,
    status: null,
    timeCreated: null,
    timeUpdated: null
}

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case orderTypes.ADD_ORDER:
          return {
           seller: {...action.data.seller},
           id: action.data.id,
           sellingPrice: action.data.item.price,
           orderedItem: {...action.data.item},
           orderId: action.data.order_id,
           transportMethod: action.data.transport_method,
           status: action.data.status,
           timeCreated: action.data.created_at,
           timeUpdated: action.data.updated_at
          };
        case orderTypes.CHANGE_PRICE:
            return{
                ...state,
                sellingPrice: action.data
            }
        default:
         return state;
    }
}

export default orderReducer;