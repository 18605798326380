export const ADD_ORDER = 'ADD_ORDER';
export const CHANGE_PRICE = 'CHANGE_PRICE';


export const addOrder = data => ({
  type: ADD_ORDER,
  data 
});

export const changePrice = data => ({
  type: CHANGE_PRICE,
  data
});


