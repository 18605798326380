export default {
  primary: '#FF9226',
  secondary: '#1c304a',
  secondarytwo: '#4A66AC',
  black: '#000',
  white: '#fff',
  medium: '#666',
  grey: '#696F79',
  danger: '#EB001B',
  dark: '#0c0c0c',
  dark2: '#333333',
  success: '#08f26e',
  greyInner: '#E3EEFF',
  background: 'rgb(229,229,229, 0.2)',
  borderGrey: '#CACDD8',
  borderGrey3: '#EFEFEF',
  borderGrey4: '#DDE0E2',
  textGrey: '#5c5c5c',
  lighterGrey: '#AAAAAA',
  lightGrey: '#666666',
  lightGrey2: '#999',
  brand: '#1C304A',
  borderGrey2: '#c4c4c4',
  checkout: '#F4F9FF',
  chatBlue: '#1A66FF',
  shadow: '#A0790033',
  shadow2: '#00000022',
};
