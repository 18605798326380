import React from 'react';
import { useSelector } from 'react-redux';
import {Redirect} from 'react-router-dom';

import {auth} from 'userRoutes';

const RouteGuard = ({children}) => {
    const {token} = useSelector(state => state.auth);
    if(!token) return <Redirect to={auth}/>
    return children;
}

export default RouteGuard;
