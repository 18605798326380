import * as imageTypes from 'store/actions/image';

const initialState = '';

const imageReducer = (state = initialState, action) => {
    switch (action.type) {
        case imageTypes.ADD_IMAGE:
            return action.data
        default:
         return state;
    }
}

export default imageReducer;