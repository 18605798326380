import * as negotiationTypes from 'store/actions/negotiation';

const initialState = {
    negotiationId : null,
}

const negotiationReducer = (state = initialState, action) => {
    switch (action.type) {
        // case negotiationTypes.ADD_NEGOTIATION:
        //   return {
       
        //   };
        case negotiationTypes.ADD_NEGOTIATION_ID:
            return{
                ...state,
                negotiationId: action.data
            }
        default:
         return state;
    }
}

export default negotiationReducer;