export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_MESSAGE_ID = 'ADD_MESSAGE_ID';

export const addMessage = data => ({
  type: ADD_MESSAGE,
  data 
});

export const addMessageId = data => ({
  type: ADD_MESSAGE_ID,
  data 
});