export const SIGN_IN = 'SIGN_IN';
export const ADD_PROFILE = 'ADD_PROFILE';
export const CHANGE_PROFILE = 'CHANGE_PROFILE';
export const ADD_ITEM = 'ADD_ITEM';
export const SIGN_IN_CLEAR = 'SIGN_IN_CLEAR';
export const REFRESH_TOKEN_AUTH = 'REFRESH_TOKEN_AUTH';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_SESSION_TIME = 'UPDATE_SESSION_TIME';
export const ALL_PERMISSIONS = 'ALL_PERMISSIONS';

export const signIn = (data) => ({
  type: SIGN_IN,
  data,
});

export const signOut = (data) => ({
  type: SIGN_OUT,
  data,
});
export const updateSessionTime = (data) => ({
  type: UPDATE_SESSION_TIME,
  data,
});

export const addProfile = (data) => ({
  type: ADD_PROFILE,
  data,
});
export const addItem = (data) => ({
  type: ADD_ITEM,
  data,
});
export const changeProfile = (data) => ({
  type: CHANGE_PROFILE,
  data,
});
// export const signInClear = data => ({
//   type: SIGN_IN_CLEAR,
//   data
// });

// export const refreshTokenAuth = data => ({
//   type: REFRESH_TOKEN_AUTH,
//   data
// });

// export const allPermissionsAction = data => ({
//   type: ALL_PERMISSIONS,
//   data
// });
