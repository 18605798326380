import React, { Suspense, useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryCacheProvider, QueryCache } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { Provider } from 'react-redux';

import routes, {
  auth,
  home,
  explore,
  item,
  whyWeRock,
  verifyaccount,
  accountSuccess,
  landingPage,
  googleAuthRedirect,
  carrierRegistration,
  shipperRegistrationCompletion,
  forgotPassword,
  carrierCreateLoginCredentials,
  stripeAccountSetupSuccess,
  shipperRegistration,
  resetPassword,
  stripePaymentStatus,
  about,
  whoYouAre,
  contact,
  faq,
} from 'userRoutes';
import RouteGuard from 'RouteGuard';
import NotFound from 'NotFound';
import { AuthProvider } from 'context/authContext';
import store from 'store/store';
import ScrollToTop from 'components/reusableComponents/ScrollToTop';
import PageLoader from 'components/reusableComponents/PageLoader';
import { updateSessionTime } from 'store/actions/auth';
import SessionTimerPrompt from 'components/auth/SessionTimerPrompt';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      retry: (failureCount, error) => {
        switch (error.response?.status) {
          case 400:
          case 401:
          case 403:
          case 404:
          case 405:
            return false;
          default:
            return failureCount < 1;
        }
      },
    },
  },
});

const unGuardedRoutes = [
  auth,
  carrierRegistration,
  carrierCreateLoginCredentials,
  shipperRegistration,
  shipperRegistrationCompletion,
  forgotPassword,
  resetPassword,
  home,
  explore,
  stripeAccountSetupSuccess,
  stripePaymentStatus,
  googleAuthRedirect,
  item,
  landingPage,
  about,
  contact,
  faq,
  whoYouAre,
  whyWeRock,
  verifyaccount,
  accountSuccess,
];

const AppUser = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      let seconds_left = store.getState().auth.expires_in;
      store.dispatch(updateSessionTime(seconds_left - 60));
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Provider store={store}>
      <AuthProvider>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <Router>
            <ScrollToTop />
            <SessionTimerPrompt />
            <Suspense fallback={<PageLoader showLoader />}>
              <Switch>
                {routes.map((route, i) => (
                  <Route key={i} exact path={route.path}>
                    {unGuardedRoutes.includes(route.path) ? (
                      <route.component />
                    ) : (
                      <RouteGuard path={route.path}>
                        {<route.component />}
                      </RouteGuard>
                    )}
                  </Route>
                ))}
                <Route path='*' component={NotFound} />
              </Switch>
            </Suspense>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </ReactQueryCacheProvider>
      </AuthProvider>
    </Provider>
  );
};

export default AppUser;
