import Axios from 'axios';

export const login = async (loginData) => {
  try {
    const { data } = await Axios.post(`login`, loginData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createAccount = async ([isUser, signupData]) => {
  try {
    const { data } = await Axios.post(
      `/${isUser ? 'customers' : 'business'}`,
      signupData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const refreshToken = async () => {
  try {
    const { data } = await Axios.post(`/refresh`, {});
    return data;
  } catch (error) {
    throw error;
  }
};

export const createCarrierAccount = async (signupData) => {
  try {
    const { data } = await Axios.post(`/carriers`, signupData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const verifyAccount = async (token) => {
  try {
    const { data } = await Axios.post(`/verify-new-account`, token);
    return data;
  } catch (error) {
    throw error;
  }
};
export const forgotPassword = async (email) => {
  try {
    const { data } = await Axios.post(`/password-reset-email`, email);
    return data;
  } catch (error) {
    throw error;
  }
};
export const resetPassword = async (resetData) => {
  try {
    const { data } = await Axios.post(`/password-reset`, resetData);
    return data;
  } catch (error) {
    throw error;
  }
};
