export const ADD_NEGOTIATION = 'ADD_NEGOTIATION';
export const ADD_NEGOTIATION_ID = 'ADD_NEGOTIATION_ID';

export const addNegotiation = data => ({
  type: ADD_NEGOTIATION,
  data 
});

export const addNegotiationId = data => ({
  type: ADD_NEGOTIATION_ID,
  data 
});