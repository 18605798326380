export const ADD_OFFER = "ADD_OFFER";
export const ADD_OFFER_ID = "ADD_OFFER_ID";

export const addOffer = (data) => ({
  type: ADD_OFFER,
  data,
});

export const addOfferId = (data) => ({
  type: ADD_OFFER_ID,
  data,
});
