export const ADD_ITEM = "ADD_ITEM";
export const ADD_ITEM_ID = "ADD_ITEM_ID";
export const ADD_ITEM_SLUG = "ADD_ITEM_SLUG";

export const addItem = (data) => ({
  type: ADD_ITEM,
  data,
});

export const addItemId = (data) => ({
  type: ADD_ITEM_ID,
  data,
});
export const addItemSlug = (data) => ({
  type: ADD_ITEM_SLUG,
  data,
});
