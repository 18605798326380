import Axios from 'axios';
import store from 'store/store';
import { message } from 'antd';
import { createBrowserHistory } from 'history';

import { auth } from 'userRoutes';
import { signOut } from 'store/actions/auth';

const axiosConfig = () => {
  const history = createBrowserHistory();
  // set axios base url
  Axios.defaults.baseURL = window['configs'].baseURL;
  // Request interceptor for API calls
  Axios.interceptors.request.use(
    (config) => {
      const token = store.getState().auth.token;

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Adding a response interceptor
  Axios.interceptors.response.use(
    (response) => {
      // if (response?.data?.status === "Token is Expired") {
      //   message.error("Your session has expired");
      //   setTimeout(() => {
      //     store.dispatch(signOut(""));
      //     window.location.replace(auth);
      //   }, 1000);
      // } else {
      return response;
      // }
    },
    (error) => {
      if (error.message === 'Network Error') return;
      // return alert(
      //   'Your Network Seems to be disconnected...Please check your internet connection.'
      // );
      if (
        error?.response?.data?.message === 'Unauthenticated' ||
        error?.response?.status === 401
      ) {
        store.dispatch(signOut(''));
        history.push(auth);
        return message.error(error?.response?.data?.message);
        // window.location.reload();
      }
      // if (error?.response?.status === 404) return;
      if (error?.response?.data?.message)
        message.error(error?.response?.data?.message);
      return Promise.reject(error);
    }
  );
};

export default axiosConfig;
