import React from "react";
import styled, { keyframes } from "styled-components";

import img from "assets/images/Offmyyard_Loader_img.png";

const PageLoader = ({ showLoader }) => {
  return (
    <Container show={showLoader}>
      <Img src={img} height={"140px"} width={"150px"} />
    </Container>
  );
};

export default PageLoader;

const Container = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
`;

const loaderAnimate = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(2);
  }
`;

const Img = styled.img`
  animation: ${loaderAnimate} 0.7s linear infinite alternate;
`;
