import React,{createContext,useReducer, useEffect} from 'react';
import authReducer, { initialState } from 'store/reducers/authReducer';

export const AuthContext = createContext();


export const AuthProvider = ({children}) => {
    
    
    const [auth, dispatch] = useReducer(authReducer, initialState);
    
    return (  
        <AuthContext.Provider value={{auth, dispatch}}>
             {children}
        </AuthContext.Provider>
    );
}


