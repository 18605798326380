import React from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

import { home } from 'userRoutes';

/**
 * Not Found Page Component - 404 page for url not found
 * E.g.:
 * ```html
 * <NotFound />
 * ```
 */
const NotFound = () => {
  const history = useHistory();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
      <Result
        status="404"
        title="Not Found"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => history.push(home)}>
            Back To home page
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
