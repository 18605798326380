import * as itemTypes from "store/actions/item";

const initialState = {
  itemId: null,
  itemSlug: null,
  itemName: null,
  description: "",
  isNegotiable: null,
  price: null,
  state: null,
  city: null,
  zipCode: null,
  virtualInspection: null,
  itemImages: [],
  ItemProperty: {},
  ItemSeller: {},
};

const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case itemTypes.ADD_ITEM:
      return {
        ...state,
        itemId: action.data.id,
        itemName: action.data.name,
        description: action.data.description,
        isNegotiable: action.data.is_negotiable,
        price: action.data.price,
        state: action.data.state,
        city: action.data.city,
        zipCode: action.data.zip_code,
        virtualInspection: action.data.vitual_inspection,
        itemImages: action.data.item_images,
        ItemProperty: { ...action.data.properties },
        ItemSeller: { ...action.data.user },
      };
    case itemTypes.ADD_ITEM_ID:
      return {
        ...state,
        itemId: action.data,
      };
    case itemTypes.ADD_ITEM_SLUG:
      return {
        ...state,
        itemSlug: action.data,
      };
    default:
      return state;
  }
};

export default itemReducer;
