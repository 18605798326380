import { combineReducers } from 'redux';
import authReducer from './authReducer';
import itemReducer from './itemReducer';
import negotiationReducer from './negotiationReducer';
import messageReducer from './messageReducer';
import orderReducer from './orderReducer';
import imageReducer from './imageReducer';
import walletReducer from './walletReducer';
import shippingOfferReducer from './shippingOfferReducer';
import bidReducer from './bidReducer';
import paymentReducer from './paymentReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  item: itemReducer,
  negotiation: negotiationReducer,
  message: messageReducer,
  order: orderReducer,
  image: imageReducer,
  wallet: walletReducer,
  bid: bidReducer,
  shippingOffer: shippingOfferReducer,
  payment: paymentReducer,
});

export default rootReducer;
