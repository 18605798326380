import * as signInTypes from 'store/actions/auth';

export const initialState = {
  token: '',
  expires_in: 0,
  profileData: '',
  success: false,
  itemId: '',
  showLogOutCountDown: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case signInTypes.SIGN_IN:
      return {
        ...state,
        token: action.data.token,
        expires_in: action.data.expires_in,
        profileData: action.data.user,
        success: true,
      };
    case signInTypes.UPDATE_SESSION_TIME:
      return {
        ...state,
        expires_in: action.data,
      };
    case signInTypes.ADD_PROFILE:
      return {
        ...state,
        profileData: action.data,
      };
    case signInTypes.CHANGE_PROFILE:
      return {
        ...state,
        profileData: { ...state['profileData'], ...action['data'] },
      };
    case signInTypes.ADD_ITEM:
      return {
        ...state,
        itemId: action.data,
      };

    case signInTypes.SIGN_OUT:
      return {
        token: action.data,
      };
    // case signInTypes.REFRESH_TOKEN_AUTH:
    //   return {
    //     ...state,
    //     accessToken: action.data.accessToken,
    //     refreshToken: action.data.refreshToken
    //   };
    // case signInTypes.SIGN_OUT:
    //   return { ...initialState, sessionTimedOut: true };
    default:
      return state;
  }
};

export default authReducer;
