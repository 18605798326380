import React from 'react'
import styled from 'styled-components'
import { Typography, Button } from 'antd'
import { useHistory } from 'react-router'

import colors from 'configs/colors'
import { heading } from 'configs/theme'

const { Paragraph } = Typography

const ModalContent = ({
  headerText,
  bodyText,
  bodyDiv,
  img,
  imgStyle,
  btnText,
  pushRoute,
  btnWidth = null,
  link,
  handleCancel,
  handleClick,
  loading,
  linkRoute,
}) => {
  const history = useHistory()
  return (
    <Container>
      {img && <Img src={img} style={imgStyle} />}
      <HeaderText>{headerText}</HeaderText>
      {bodyText && <BodyText>{bodyText}</BodyText>}
      {bodyDiv && <BodyDiv>{bodyDiv}</BodyDiv>}
      {btnText && Array.isArray(btnText) ? (
        <BtnDiv>
          <Btn
            background={btnText[0].background}
            color={btnText[0].color}
            border={btnText[0].border}
            onClick={handleCancel}
            width={btnWidth}
          >
            {btnText[0].text}
          </Btn>
          <Btn
            background={btnText[1].background}
            color={btnText[1].color}
            border={btnText[1].border}
            onClick={handleClick}
            loading={loading}
            width={btnWidth}
          >
            {btnText[1].text}
          </Btn>
        </BtnDiv>
      ) : (
        <Btn
          width={btnWidth}
          background={colors.primary}
          color={colors.white}
          onClick={handleClick ? handleClick : () => history.push(pushRoute)}
        >
          {btnText}
        </Btn>
      )}
      {link && (
        <LinkText onClick={() => history.push(linkRoute)}>{link}</LinkText>
      )}
    </Container>
  )
}

export default ModalContent

const Container = styled.div`
  /* background: red; */
  min-height: 3rem;
  width: 100%;
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Img = styled.img`
  display: block;
  margin: auto;
  margin-bottom: 1.5rem;
`
const HeaderText = styled(Paragraph)`
  text-align: center;
  font-weight: ${heading.weight};
  font-size: ${heading.size};
`
const BodyText = styled(Paragraph)`
  text-align: center;
`
const BodyDiv = styled.div`
  display: flex;
  justify-content: center;
`

const Btn = styled(Button)`
  display: block;
  margin: auto;
  width: ${({ width }) => width};
  margin-top: 0.7rem;
  height: 2.7rem;
  border-radius: 3px;
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  border: ${({ border }) => border && border};
`

const BtnDiv = styled.div`
  width: 90%;
  display: flex;
  margin: auto;
  margin-top: 1.5rem;
  justify-content: space-between;
  margin-bottom: 0rem;
  gap: 7%;
`

const LinkText = styled(Paragraph)`
  text-align: center;
  text-decoration: underline;
  color: ${colors.primary};
  margin-top: 1rem;
  cursor: pointer;
`
