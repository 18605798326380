import { lazy } from 'react';

// const Home = lazy(() => import('./pages/user/Home'));
const Home = lazy(() => import('./pages/user/CarServicesLanding'));
const Auth = lazy(() => import('./pages/user/auth/Auth'));
const CarrierRegistration = lazy(() =>
  import('pages/user/auth/CarrierRegistration')
);
const CarrierCreateLoginCredentials = lazy(() =>
  import('pages/user/auth/CarrierCreateLoginCredentials')
);

const ShipperRegistration = lazy(() =>
  import('pages/user/auth/ShipperRegistration')
);

const ShipperRegistrationCompletion = lazy(() =>
  import('pages/user/auth/ShipperRegistrationCompletion')
);
const ForgotPassword = lazy(() => import('pages/user/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/user/auth/ResetPassword'));
const Explore = lazy(() => import('./pages/user/Explore'));
const VerifyAccount = lazy(() => import('pages/user/VerifyAccount'));
const AccountSuccess = lazy(() => import('pages/user/AccountSuccess'));
const StripeAccountSetupSuccess = lazy(() =>
  import('pages/user/StripeAccountSetupSuccess')
);
const StripePaymentStatus = lazy(() =>
  import('pages/user/StripePaymentStatus')
);
const Sell = lazy(() => import('pages/user/Sell'));
const EditItem = lazy(() => import('pages/user/EditItem'));
const Item = lazy(() => import('pages/user/Item'));
const WhoYouAre = lazy(() => import('pages/user/WhoYouAre'));
const FAQ = lazy(() => import('pages/user/FAQ'));
const About = lazy(() => import('pages/user/About'));
const Contact = lazy(() => import('pages/user/Contact'));
const WhyWeRock = lazy(() => import('pages/user/WhyWeRock'));
const Message = lazy(() => import('pages/user/Message'));
const Checkout = lazy(() => import('pages/user/Checkout'));
const Stripe = lazy(() => import('pages/user/Stripe'));
const Negotiation = lazy(() => import('pages/user/Negotiation'));
const SavedItems = lazy(() => import('pages/user/dashboard/SavedItem'));
const FavouriteItems = lazy(() =>
  import('pages/user/dashboard/FavouriteItems')
);
const Wallet = lazy(() => import('pages/user/dashboard/Wallet'));
const MeetingSchedule = lazy(() =>
  import('pages/user/dashboard/MeetingSchedule')
);
const Profile = lazy(() => import('pages/user/dashboard/Profile'));
const TransactionHistory = lazy(() =>
  import('pages/user/dashboard/TransactionHistory')
);
const BankAccount = lazy(() => import('pages/user/dashboard/BankAccount'));
const MessageListings = lazy(() =>
  import('pages/user/dashboard/MessageListings')
);
const NegotiationListings = lazy(() =>
  import('pages/user/dashboard/NegotiationListings')
);
const TransportMethod = lazy(() => import('pages/user/TransportMethod'));
const InspectionSchedule = lazy(() => import('pages/user/InspectionSchedule'));
const Offer = lazy(() => import('pages/user/dashboard/Offer'));
const LandingPage = lazy(() => import('pages/user/autoinspection/LandingPage'));
const GoogleAuthRedirect = lazy(() => import('pages/user/GoogleAuthRedirect'));

export const home = '/';
// export const carServicesLanding = '/';
export const auth = '/auth';
export const carrierRegistration = '/signup/carrier';
export const carrierCreateLoginCredentials = '/carrier/create/loginCredentials';
export const shipperRegistration = '/signup/shipper';
export const shipperRegistrationCompletion = '/shipper/registration';
export const forgotPassword = '/auth/forgot-password';
export const resetPassword = '/auth/resetpassword';
export const explore = '/explore';
export const verifyaccount = '/verifyaccount';
export const accountSuccess = '/accountSuccess';
export const stripeAccountSetupSuccess = '/stripe-account-setup/success';
export const stripePaymentStatus = '/stripe-payment-status';
export const sell = '/sell';
export const googleAuthRedirect = '/googleAuthRedirect';
export const editItem = '/editItem';
export const item = '/item';
export const whoYouAre = '/whoYouAre';
export const faq = '/faq';
export const about = '/about';
export const contact = '/contact';
export const whyWeRock = '/whyWeRock';
export const message = '/message';
export const checkout = '/checkout';
export const stripe = '/stripe';
export const negotiation = '/negotiation';
export const savedItems = '/dashboard/savedItems';
export const favouriteItems = '/dashboard/favouriteItems';
export const wallet = '/dashboard/wallet';
export const meetingSchedule = '/dashboard/meetingSchedule';
export const profile = '/dashboard/profile';
export const transactionHistory = '/dashboard/transactionHistory';
export const bankAccount = '/dashboard/bankAccount';
export const messageListings = '/dashboard/messageListings';
export const negotiationListings = '/dashboard/negotiationListings';
export const offer = '/dashboard/offer';
export const transportmethod = '/transportmethod';
export const inspectionSchedule = '/inspectionSchedule';
export const landingPage = '/autoinspection/landingPage';

export default [
  { path: home, component: Home },
  // { path: carServicesLanding, component: CarServicesLanding },
  { path: auth, component: Auth },
  { path: carrierRegistration, component: CarrierRegistration },
  {
    path: carrierCreateLoginCredentials,
    component: CarrierCreateLoginCredentials,
  },
  {
    path: shipperRegistrationCompletion,
    component: ShipperRegistrationCompletion,
  },
  {
    path: shipperRegistration,
    component: ShipperRegistration,
  },
  { path: forgotPassword, component: ForgotPassword },
  { path: resetPassword, component: ResetPassword },
  { path: explore, component: Explore },
  { path: verifyaccount, component: VerifyAccount },
  { path: accountSuccess, component: AccountSuccess },
  { path: stripeAccountSetupSuccess, component: StripeAccountSetupSuccess },
  { path: stripePaymentStatus, component: StripePaymentStatus },
  { path: sell, component: Sell },
  { path: googleAuthRedirect, component: GoogleAuthRedirect },
  { path: editItem, component: EditItem },
  { path: item, component: Item },
  { path: whoYouAre, component: WhoYouAre },
  { path: contact, component: Contact },
  { path: faq, component: FAQ },
  { path: about, component: About },
  { path: whyWeRock, component: WhyWeRock },
  { path: message, component: Message },
  { path: checkout, component: Checkout },
  { path: stripe, component: Stripe },
  { path: negotiation, component: Negotiation },
  { path: savedItems, component: SavedItems },
  { path: favouriteItems, component: FavouriteItems },
  { path: wallet, component: Wallet },
  { path: meetingSchedule, component: MeetingSchedule },
  { path: profile, component: Profile },
  { path: transactionHistory, component: TransactionHistory },
  { path: bankAccount, component: BankAccount },
  { path: messageListings, component: MessageListings },
  { path: negotiationListings, component: NegotiationListings },
  { path: transportmethod, component: TransportMethod },
  { path: inspectionSchedule, component: InspectionSchedule },
  { path: offer, component: Offer },
  { path: landingPage, component: LandingPage },
];
