import * as shippingOfferTypes from "store/actions/shippingOffers";

const initialState = "";

const shippingOfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case shippingOfferTypes.ADD_OFFER:
      return action.data;
    default:
      return state;
  }
};

export default shippingOfferReducer;
