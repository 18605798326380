import React from 'react';

import AppText from 'components/reusableComponents/AppText';
import colors from 'configs/colors';

const LogoutCountDown = ({ minutes, seconds }) => {
  return (
    <AppText
      value={
        <>
          Session time left:{' '}
          <span style={{ fontWeight: '600' }}>
            {minutes}mins : {seconds}secs
          </span>
        </>
      }
      size={'16px'}
      color={colors.danger}
    />
  );
};

export default LogoutCountDown;
