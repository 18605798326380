import * as paymentTypes from 'store/actions/payment';

const initialState = {
  client_secret: '',
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case paymentTypes.ADD_CLIENT_SECRET:
      return {
        ...state,
        client_secret: action.data,
      };
    default:
      return state;
  }
};

export default paymentReducer;
