/** load redux state */
export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    sessionStorage.removeItem('state');
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
/** save redux state */
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (err) {
    console.log(err);
  }
};

export const isFileImage = (file) => {
  return file && file['type'].split('/')[0] === 'image';
};

export const addCommasToNumber = (string) => Number(string).toLocaleString();

export const commaFormattedValue = (value) =>
  (Number(value.replace(/\D/g, '')) || '').toLocaleString();

export const removeSpecialCharacters = (string) =>
  string.replace(/[^\w\s]/gi, '').replace(/[A-Za-z]/g, '');

export const limitItemDescription = (description, delimiter) => {
  const descriptionSubstring = description.substring(0, delimiter);

  return description.length > 25
    ? `${descriptionSubstring.slice(
        0,
        descriptionSubstring.lastIndexOf(' ')
      )}...`
    : description;
};

export const isPdfLink = (url) => {
  return url.slice(url.lastIndexOf('.') + 1) === 'pdf';
};

export const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const showChatContact = (isMobile, showActiveChat) => {
  if (isMobile && !showActiveChat) {
    return true;
  } else if (!isMobile) {
    return true;
  } else {
    return false;
  }
};

export const showChatActive = (isMobile, showActiveChat) => {
  if (isMobile && showActiveChat) {
    return true;
  } else if (!isMobile) {
    return true;
  } else {
    return false;
  }
};

export const objectToFormData = (object) => {
  const formData = new FormData();
  for (let key in object) {
    Array.isArray(object[key])
      ? object[key].forEach((value) => formData.append(key + '[]', value))
      : formData.append(key, object[key]);
  }

  return formData;
};
