import React from 'react';
import ReactDOM from 'react-dom';

import { createBrowserHistory } from 'history';

import './index.css';
import './styles/global.css';
import { saveState } from 'utils';
import store from 'store/store';
import AppUser from 'AppUser';
import reportWebVitals from './reportWebVitals';
import axiosConfig from 'configs/js/axiosConfig';

export const history = createBrowserHistory();

window.onbeforeunload = () => {
  if (store.getState().auth.profileData.id) {
    saveState(store.getState());
  }
};

axiosConfig();
ReactDOM.render(
  <React.StrictMode>
    <AppUser />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
